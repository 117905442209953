.sgl-button-layout{
	padding: 30px 3.5% 20px 3.5%;
}

.sgl-button-layout.coth{
	padding: 30px 0px 20px 0px;
}

.sgl-button-layout .sgl-title-section h1{
	font-size: 20px;
	font-weight: bold;
	padding: 0px;
	margin-bottom: 30px;
	line-height: 24px;
	margin-top: 0px;
}

.sgl-button-layout.coth .sgl-title-section h1{
	font-size: 24px;
}

.sgl-button-layout .card-deck {
	overflow: hidden;
	display: grid;
	grid-auto-rows: 1fr;
	grid-column-gap: 4.5%;
	grid-row-gap: 30px;
}

.sgl-button-layout .card-deck .card-item{
	position:relative;
}

.sgl-button-layout .card-deck .card-item .bar{
	height:8px;
	background-color:rgb(40,94,60);
	position:absolute;
	top:0px;
	left:0px;
	right:0px;
	display:block;
	z-index: 1;
}

.sgl-button-layout.coth .card-deck .card-item .bar{
	height:8px;
	background-color:#000000;
	position:absolute;
	top:0px;
	left:0px;
	right:0px;
	display:block;
	z-index: 1;
}

.sgl-button-layout .card-deck .card-item .card {
	position: relative;
	word-wrap: break-word;
	border: 2px solid rgb(40,94,60);
	min-height: 1px;
	height: 100% !important;
	transition: 0.5s ease;
	border-radius: 0;
	max-width: 100%;
	overflow: hidden;
	min-width: 215px;
	cursor:initial;
}

.sgl-button-layout.coth .card-deck .card-item .card {
	position: relative;
	word-wrap: break-word;
	border: 2px solid #3E272D;
	min-height: 1px;
	height: 100% !important;
	transition: 0.5s ease;
	border-radius: 0;
	max-width: 100%;
	overflow: hidden;
	min-width: 215px;
	cursor:initial;
}

.sgl-button-layout .card-deck .card-item .card:hover {
	box-shadow: 0px 0px 20px -3px rgba(143, 143, 143, 0.75);
}

.sgl-button-layout .card-deck .card-item .card:hover,
.sgl-button-layout .card-deck .card-item .card:hover .card-header,
.sgl-button-layout .card-deck .card-item .card:hover .card-footer {
	border-color: rgba(40,94,60,0.5);
}

.sgl-button-layout.coth .card-deck .card-item .card:hover,
.sgl-button-layout.coth .card-deck .card-item .card:hover .card-header,
.sgl-button-layout.coth .card-deck .card-item .card:hover .card-footer {
	border-color: rgba(0, 0, 0,0.5);
}

.sgl-button-layout .card-deck .card-item .card a {
	text-decoration: none;
}

.sgl-button-layout .card-deck .card-item .card .card-header {
	padding: 18px 18px 10px 18px;
	margin-bottom: 0;
	border-radius: 0;
	transition: 0.5s ease;
}

.sgl-button-layout .card-deck .card-item .card .card-title {
	padding: 0px 20px 0px 0px;
	margin: 0;
}

.sgl-button-layout .card-deck .card-item .card .card-title a {
	color: #333;
	font-size: 16px;
	text-transform: uppercase;
	line-height:19px;
}

.sgl-button-layout.coth .card-deck .card-item .card .card-title a {
	color: #000000;
	font-size: 18px;
}

.sgl-button-layout .card-deck .card-item .card .card-body {
	position: relative;
	z-index: 0;
	overflow: hidden;
	padding: 10px 18px;
}

.sgl-button-layout.coth .card-deck .card-item .card .card-body p{
	font-family: "Work Sans", sans-serif;
}

.sgl-button-layout .card-deck .card-item .card .card-body p span {
	font-weight:500;
	text-transform: uppercase;
	margin-right: 5px;
	font-size:15px;
	color:#000;
}

.sgl-button-layout .card-deck .card-item .card .card-body .orange{
	color:#e6b332;
}

.sgl-button-layout .card-deck .card-item .card .card-body .green{
	color:#285e3c;
}

.sgl-button-layout.coth .card-deck .card-item .live{
	font-family: 'Work Sans', sans-serif;
}

.sgl-button-layout .card-deck .card-item .live{
    border-left: 50px solid transparent;
    border-right: 50px solid red;
    border-bottom: 50px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 1;
    text-indent:21px;
    font-size:13px;
    line-height:28px;
    color:#fff;
	font-weight:bold;
}

.sgl-button-layout .card-deck .card-item .card .card-body .card-text {
	margin: 0px 0px 10px 0px;
	font-size: 15px;
	color: #5F5E5E;
	font-weight:500;
}

.sgl-button-layout .card-deck .card-item .card .card-body .card-text:last-child {
	margin: 0px;
}

.sgl-button-layout .card-deck .card-item .card .card-footer {
	padding: 12px 18px;
	transition: 0.5s ease;
	text-align: center;
	box-sizing: border-box;
	height:66px;
}

.sgl-button-layout .card-deck .card-item .card .card-footer .btn {
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 11px 24px;
	font-size: 15px;
	line-height: 100%;
	border-radius: 15px;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	color: #fff;
	background-color: rgb(40,94,60);
	border-color: rgb(40,94,60);
	position: absolute;
	left:18px;
	right:0px;
	/* margin:0px auto; */
	width:165px;
	bottom:18px;
	cursor: pointer;
}

.sgl-button-layout.coth .card-deck .card-item .card .card-footer .btn {
	color: #fff;
	background-color: #873F41;
	border-color: #873F41;
	font-family: 'Work Sans', sans-serif;
}

.sgl-related-content .sgl-button-layout {
	padding-top: 25px;
	padding-bottom: 50px;
}

.sgl-button-layout * {
    font-family: Freigeist,sans-serif;
}

.sgl-button-layout.coth * {
    font-family: Playfair Display;
}

@media (max-width: 1023px) {
	.sgl-button-layout .card-deck {
		grid-column-gap: 2%;
		grid-row-gap: 20px;
	}
}

@media (max-width: 767px) {
	.sgl-button-layout {
		padding-left: 30px;
		padding-right: 30px;
	}

	.sgl-related-content .sgl-button-layout {
		padding-left: 15px;
		padding-right: 15px;
	}

	.sgl-button-layout .card-deck {
		grid-template-columns: repeat(1, 1fr) !important;
		grid-auto-rows: minmax(min-content, max-content);
	}	

	.sgl-button-layout .card-deck .card-item .card .card-footer .btn {
		font-size:12px;
		padding:10px 14px;
		width:130px;
	}

	.sgl-button-layout .card-deck .card-item .card .card-header{
		padding-top:25px;
	}

	.sgl-button-layout .card-deck .card-item .card .card-footer{
		height:73px;
	}

	.sgl-button-layout .card-deck .card-item .card .card-footer .btn{
		bottom:25px;
	}
}