.class-details .oog-status:empty{display:block}
 .liveclasscss #liveclassdetail.sgl-plugin .sgl-secondary-header th{vertical-align: middle;}
 .liveclasscss #liveclassdetail.sgl-plugin .div-responsive>table{margin-bottom: 30px;}
 .liveclasscss #liveclass_livenow td:first-child{font-weight: bold;}
 .liveclasscss #liveclass_livenow .live_row,  .liveclasscss #liveclass_livenow .live_row td{background-color: green !important; font-weight: bold;color: white;}
 .liveclasscss #liveclass_livenow .live_timer{font-size: 18px;}
 .liveclasscss #liveclass_livenow .cd_timer,  .liveclasscss #liveclass_livenow .live_timer.cd_timer{color: yellow !important;}
 .liveclasscss .liveclass_leaderboard .jog_row,  .liveclasscss .liveclass_leaderboard .jog_row td,  .liveclasscss .liveclass_leaderboard .joq_row,  .liveclasscss .liveclass_leaderboard .joq_row td{background-color: #91D445 !important;}
 .liveclasscss .liveclass_leaderboard .completed_jo_row, 
 .liveclasscss .liveclass_leaderboard .completed_jo_row td, 
 .liveclasscss table > tbody > tr.sgl-secondary-header > td.completed_jo_row {
    background-color: #91D445 !important;
 }
 .liveclasscss .liveclass_leaderboard .upcoming_jo_row, .liveclasscss .liveclass_leaderboard .upcoming_jo_row td {background-color: #FEFFAE !important;}
 .liveclasscss .liveclass_leaderboard .jog_row .textChanges,  .liveclasscss .liveclass_leaderboard .joq_row .textChanges, .liveclasscss .liveclass_leaderboard .upcoming_jo_row .textChanges{font-size: 12px;white-space: nowrap;}
 .liveclasscss #liveclass_livenow .next_row,  .liveclasscss #liveclass_livenow .next_row td{font-style: italic;}
 .liveclasscss .derby_next_row {background-color: #f1f1f1 !important;}
 .liveclasscss .hunter-herby-live-score .sgl-secondary-header td,  .liveclasscss .hunter-herby-live-score-mobile .sgl-secondary-header td{background-color: #d6d6d6 !important; font-weight: bold;}
 .liveclasscss .hunter-herby-live-score{margin-bottom: 0 !important;}
 .liveclasscss .hunter-herby-live-score-mobile{margin-bottom: 20px !important;}
 .liveclasscss #derby-live-cell{padding: 0 !important;}
 .liveclasscss .rider_flag_inline_image{
    width: 22px;
    margin-right: 3px;
    vertical-align: middle;
    padding-top: 1px;
}

 .liveclasscss .rider_flag_mobile{width:50px;}
 .liveclasscss .mobile_row span.entry{
    font-weight: bold;
    text-overflow: ellipsis;
    /* display: block; */
    width: 150px;
    overflow: hidden;}
 .liveclasscss .hunter_result,  .liveclasscss .jumper_result{clear: both; white-space: nowrap;}
 .liveclasscss .jumper_result div{width: 24%;display: inline-block;} 
 .liveclasscss .hunter_result div{display: table-cell;padding-right: 10px;float: left;}
 .liveclasscss .jumper_result div label,  .liveclasscss .hunter_result div label{display: inline-block;margin: 0px;}

 .liveclasscss .with_wrapping_text{
    /* float: left; */
    margin-bottom: 8px;
}

 .liveclasscss .formatData{
    margin: 20px;
    border: none;
    font-weight: bold;
    text-decoration: underline;
}

 .liveclasscss #CDM{
    border: none;
}

 .liveclasscss .textChanges{
    font-size: 18px;
    font-weight: bolder;
    vertical-align: middle;
}
 .liveclasscss .mobile_row .textChanges{
    font-size: 24px;
}
 .liveclasscss .team_name{
    font-size: 14px;
    font-weight: bold;
    color: black;
}
 .liveclasscss .team_Score{
    font-weight: bolder;
    color: green;
}
 .liveclasscss .not_live_msg{
    font-size: 1.25em;
    font-weight: bolder;
    font-family: "Lato", sans-serif;
}

 .liveclasscss .floatRight{
    float: right;
}

 /* .liveclasscss .floatLeft{
    float: left;
} */
 .liveclasscss .verticle-align-center{
    vertical-align: middle !important;
}
 .liveclasscss .lessWidth{
    width: 7%;
}

 .liveclasscss .white_bg{
    background-color: white !important;
}

 .liveclasscss .mobile-hr{
    border-top: 1px solid #c9c9c9 !important;
    margin: 4px !important;
    opacity: unset;
}
 .liveclasscss .placing-icon1{
    width: 16px;
    height: 16px;
}
.team_logo_flag{
    width: 80%;
    max-width: 100px;
    margin-bottom: 10px;
    text-align: center;
}
.team_logo_flag_mobile{
    margin-bottom: 10px;
    width: 60px;
}

 .liveclasscss .sgl-anchor td{
    padding: 0px !important;
}
 .liveclasscss .sgl-anchor td a{
    display: block;
    padding: 8px;
}
 .liveclasscss .liveclass_livenow.mobile,  .liveclasscss .liveclass_leaderboard.mobile,  .liveclasscss .liveclass_linedup.mobile {
    display: none;
}

.liveclasscss .liveclass_livenow:not(.mobile),  .liveclasscss .liveclass_leaderboard:not(.mobile),  .liveclasscss .liveclass_linedup:not(.mobile) {
    width: 100%;
    max-width: 950px;
}

 .liveclasscss .delta_up,  .liveclasscss .delta_down{white-space: nowrap;}
 .liveclasscss .delta_up{color: green !important;}
 .liveclasscss .delta_down{color: red !important;}
 .liveclasscss .liveclass_leaderboard .video_icon{
    margin-right: 4px;
    margin-top: -4px;
}
 .liveclasscss .SEventContainer #live_stream_iframe{
    margin: 0 auto;
    width: 100%;
    height: 450px;
    border: 0px;
}
.liveclasscss .fullWidthContainer { width: 100%; }

@media all and (max-width:820px){
     .liveclasscss .placing-icon1{
        width: 14px;
        height: 14px;
        padding-top:1px;
    }
     .liveclasscss .SEventContainer #live_stream_iframe{
        height: 350px;
    }    
}

@media all and (max-width:600px){
     .liveclasscss .placing-icon1{
        width: 12px;
        height: 12px;
    }

     .liveclasscss .liveclass_livenow.mobile,  .liveclasscss .liveclass_leaderboard.mobile,  .liveclasscss .liveclass_linedup.mobile {
        display: table;
    }

     .liveclasscss .liveclass_livenow:not(.mobile),  .liveclasscss .liveclass_leaderboard:not(.mobile),  .liveclasscss .liveclass_linedup:not(.mobile) {
        display: none;
    }
     .liveclasscss .delta_up,  .liveclasscss .delta_down{clear: both;display: block;}
     
     .liveclasscss .liveclass_leaderboard .mobile_row.upcoming_jo_row td.placings_data, .liveclasscss .liveclass_leaderboard .mobile_row.completed_jo_row td.placings_data,
     .liveclasscss .liveclass_leaderboard .mobile_row.jog_row td.placings_data, .liveclasscss .liveclass_leaderboard .mobile_row.joq_row td.placings_data{
        overflow: hidden;
        position: relative;
        font-size: 14px;
    }
     .liveclasscss .jog_badge,  .liveclasscss .joq_badge{
        background-color: lightgreen;
        height: 20px;
        left: -10px;
        position: absolute;
        top: -10px;
        width: 20px;
        -webkit-transform: rotate(-45deg);      
    }
     .liveclasscss .liveclass_leaderboard .jog_row, .liveclass_leaderboard .jog_row td,  .liveclasscss .liveclass_leaderboard .joq_row,  .liveclasscss .liveclass_leaderboard .joq_row td{background-color: transparent !important;}
     .liveclasscss .liveclass_leaderboard .video_icon{
        margin-right: 10px;
        width: 30px;
        margin-top: 0px;
    }

     .liveclasscss .SEventContainer #live_stream_iframe{
        height: 220px;
    }    
}

.liveclasscss .credit-text {
    float: right;
    font-family: Lucida Sans,Lucida Sans Regular,Lucida Grande,Lucida Sans Unicode,Geneva,Verdana,sans-serif;
    font-size: 12px;
}

.liveclasscss .sponsor_text {
    color: grey;
}

.liveclasscss .jumper-p {
    margin-bottom: 5px!important;
}

.sgl-video-section .sgl-plugin-page #sgl-content-wrapper .sgl-plugin table .sgl-primary-header, .sgl-video-section .sgl-plugin-page #sgl-content-wrapper .sgl-plugin .sgl-primary-header, .sgl-video-section .sgl-plugin-page #sgl-content-wrapper .sgl-plugin .sgl-primary-header *, .sgl-video-section .sgl-plugin-page .mymodal table .sgl-primary-header, .sgl-video-section .sgl-plugin-page .mymodal table .sgl-primary-header th, .sgl-video-section .sgl-plugin-page .mymodal .sgl-primary-header, .sgl-video-section .sgl-plugin-page .mymodal .sgl-primary-header *, .sgl-video-section .sgl-plugin-page .sgl-plugin table .sgl-primary-header th {
    color: #f7f7f7 !important;
    background-color: rgb(40,94,60) !important;
}

.sgl-plugin-page .sgl-plugin .table>caption+thead>tr:first-child>td, .sgl-plugin-page .sgl-plugin .table>caption+thead>tr:first-child>th, .sgl-plugin-page .sgl-plugin .table>colgroup+thead>tr:first-child>td, .sgl-plugin-page .sgl-plugin .table>colgroup+thead>tr:first-child>th, .sgl-plugin-page .sgl-plugin .table>thead:first-child>tr:first-child>td, .sgl-plugin-page .sgl-plugin .table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.sgl-plugin-page .mymodal .table>tbody>tr>td, .sgl-plugin-page .mymodal .table>tbody>tr>th, .sgl-plugin-page .mymodal .table>tfoot>tr>td, .sgl-plugin-page .mymodal .table>tfoot>tr>th, .sgl-plugin-page .mymodal .table>thead>tr>td, .sgl-plugin-page .mymodal .table>thead>tr>th, .sgl-plugin-page .sgl-plugin .table>tbody>tr>td, .sgl-plugin-page .sgl-plugin .table>tbody>tr>th, .sgl-plugin-page .sgl-plugin .table>tfoot>tr>td, .sgl-plugin-page .sgl-plugin .table>tfoot>tr>th, .sgl-plugin-page .sgl-plugin .table>thead>tr>td, .sgl-plugin-page .sgl-plugin .table>thead>tr>th {
    border-top: 1px solid #ddd;
    line-height: 1.42857143;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}

.sgl-plugin-page .sgl-plugin table.contains-border td, .sgl-plugin-page .sgl-plugin table.contains-border th {
    border: 1px solid #ddd;
}

.uppercase, h6, th, span.widget-title {
    line-height: 1.05;
    letter-spacing: .05em;
    text-transform: uppercase;
}

th, td {
    padding: 0.5em;
    text-align: left;
    border-bottom: 1px solid #ececec;
    line-height: 1.3;
    font-size: .9em;
}

.sgl-plugin-page .sgl-plugin table.table tr.sgl-secondary-header th {
    background-color: #f1f1f1;
}

.sgl-plugin-page .center-align {
    text-align: center!important;
}

.sgl-plugin-page .mymodal .table>tbody>tr>td, .sgl-plugin-page .mymodal .table>tbody>tr>th, .sgl-plugin-page .mymodal .table>tfoot>tr>td, .sgl-plugin-page .mymodal .table>tfoot>tr>th, .sgl-plugin-page .mymodal .table>thead>tr>td, .sgl-plugin-page .mymodal .table>thead>tr>th, .sgl-plugin-page .sgl-plugin .table>tbody>tr>td, .sgl-plugin-page .sgl-plugin .table>tbody>tr>th, .sgl-plugin-page .sgl-plugin .table>tfoot>tr>td, .sgl-plugin-page .sgl-plugin .table>tfoot>tr>th, .sgl-plugin-page .sgl-plugin .table>thead>tr>td, .sgl-plugin-page .sgl-plugin .table>thead>tr>th {
    border-top: 1px solid #ddd;
    line-height: 1.42857143;
    padding: 8px;
    text-align: left;
    vertical-align: top;
}


.sgl-plugin-page .liveclasscss #liveclass_livenow td:first-child {
    font-weight: 700;
}

.sgl-plugin-page .liveclasscss #liveclass_livenow .next_row, .sgl-plugin-page .liveclasscss #liveclass_livenow .next_row td {
    font-style: italic;
}

td {
    color: #666;
}

/* .sgl-plugin-page .mymodal, .sgl-plugin-page .sgl-plugin {
    background-color: #fff;
    color: #333;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif!important;
} */

tbody{
    background-color: #fff;
}

@media screen and (max-width: 768px)
{.sgl-plugin-page {
    padding: 0 8px!important;
}}

.blocklist-section--no-bottom-padding {
    overflow-x: hidden !important;
}