.sgl-view-all .sgl-title-section h1{
	font-size: 28px;
	font-weight: bold;
	padding: 0px 15px;
	margin-bottom: 0px;
}

.sgl-view-all .card-deck {
	overflow: hidden;
	display: grid;
	grid-auto-rows: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 40px;
	padding: 15px 55px ;
	-moz-osx-font-smoothing: auto;
	font-size: 100%;
	line-height: 100%;
}


.sgl-view-all .card-deck .sgl-item{
	position:relative;
	padding: 0px;
}

.sgl-view-all .card-deck .sgl-item:last-child {
	padding-right: 0;
}

.sgl-view-all .card-deck .sgl-post {
	margin-bottom: 0px;
}


.sgl-view-all .card-deck .sgl-standard-post {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.sgl-view-all .card-deck .sgl-standard-post .sgl-post-content {
    padding-top: 10px;
}

.sgl-view-all .card-deck .sgl-standard-post .sgl-post-content h2 {
    color: #333333;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
}

.sgl-view-all .card-deck .sgl-standard-post .sgl-post-content h2 a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #333333;
}


.sgl-view-all .sgl-post {
  position: relative;
}

.sgl-view-all .sgl-post .sgl-post-gallery {
  position: relative;
  cursor: pointer;
}

.sgl-view-all .sgl-post img {
  width: 100%;
}

.sgl-view-all .sgl-post a.sgl-category-post {
  position: absolute;
  bottom: 10px;
  left: 20px;
}

.sgl-view-all a.sgl-category-post {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #ffffff;
  font-size: 11px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 400;
  text-transform: uppercase;
  padding: 2px 10px;
  background: #f44336;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}



.sgl-view-all .sgl-standard-post {
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.sgl-view-all .sgl-standard-post .sgl-post-content {
  padding-top: 5px;
}

.sgl-view-all .sgl-standard-post .sgl-post-content h2 {
  color: #333333;
  font-size: 13px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 700;
  line-height: 20px;
  margin: 5px 0 10px;
}

.sgl-view-all .sgl-standard-post .sgl-post-content h2 a {
  display: inline-block;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #333333;
}


.sgl-view-all .sgl-title-section {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 30px;
  margin-top: -30px;
  padding: 45px;
  background-color: #285E3C;
}

.sgl-view-all .sgl-title-section h1 {
  color: #222222;
  font-size: 25px;
  /* font-family: 'Lato', sans-serif; */
  font-weight: 700;
  margin: 0 0 30px;
  text-transform: uppercase;
  margin-bottom: -1px;
}

.sgl-view-all .sgl-title-section h1 span {
  display: inline-block;
  padding-bottom: 16px;
  padding-right: 5px;
  font-size: 25px;
  border-bottom: 1px solid #f44336;
}

.sgl-view-all .sgl-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.sgl-view-all .sgl-post-gallery .sgl-play {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.sgl-view-all .sgl-post-gallery .sgl-play .sgl-play-icon {
  height: 40px;
  width: 40px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background: rgba(220, 220, 220, 0.6);
  position: absolute;
  align-items: center;
  font-size: 18px;
  display: flex;
  color: #fff;
  margin-top: -20px;
  margin-left: -20px;
  text-indent: 4px;
}

.sgl-view-all .sgl-post-gallery .sgl-play span {
  display: inline-block;
  margin: 0px auto;
}

.sgl-view-all .sgl-video-state{
  font-weight: bold;
  position: absolute;
  top:5px;
  right:5px;
  color:#fff;
  background-color:#222;
  font-size:12px;
  padding:5px 10px 3px 10px;
  border:1px solid #aaa;
  line-height:12px;
  border-radius: 3px;
  text-shadow: none;
  letter-spacing: 0.6px;
}

.sgl-view-all{
  border:none;
  background:none;
  padding:25px 0px 50px;
}

.sgl-view-all * {
  font-family: Freigeist,sans-serif;
}

.sgl-view-all .sgl-container {
  width:auto;
  padding-left:3.5%;
  padding-right:3.5%;
}

.sgl-view-all  ul.sgl-post-tags {
  display:none;
}

.sgl-view-all .sgl-title-section{
  border:none;
}

.sgl-view-all .sgl-title-section h1{
  margin:0px;
  border:none;
  font-size:19px;
  line-height:24px;
}

.sgl-view-all .sgl-title-section h1 span{
  padding:0px;
  border:none;
  color:#000;
  text-transform: capitalize;
  font-size:30px;
}

.sgl-view-all .sgl-title-section h2{
  margin-top: 1vw;
  border:none;
  font-size:10px;
  line-height:24px;
}

.sgl-view-all .sgl-title-section h2 span{
  padding:0px;
  border:none;
  color:rgb(246, 239, 239);
  text-transform: capitalize;
  padding-left: 15px;
  font-size:1vw;
}

.sgl-view-all .sgl-title-section h1 span:after{
  content: "\2192";
  padding-left:10px;
  position: relative;
  top: -3px;
}

.sgl-view-all .sgl-standard-post .sgl-post-content h2{
  font-size:15px;
}

.sgl-view-all .sgl-standard-post .sgl-post-content h2 span{
  font-size:15px;
}

.sgl-view-all .sgl-standard-post .sgl-post-content h2 a{
  text-transform: capitalize;
  color:#000;
  font-size: 15px;
  cursor:text;
}

.sgl-view-all .sgl-title-section h1{
  margin:0px;
  border:none;
  font-size:25px;
  line-height:24px;
}

.sgl-view-all .sgl-title-section h1 span{
  padding:0px;
  border:none;
  color:rgb(252, 250, 250);
  text-transform: capitalize;
  font-size:2vw;
}

.sgl-view-all .sgl-title-section h1 span:after{
  content: "\2192";
  padding-left:10px;
  font-size: 16px;
  position: relative;
  top: -3px;
}

@media (max-width: 767px) {
	.sgl-view-all .card-deck {
		grid-template-columns: repeat(2, 1fr) !important;
    padding: 15px 30px ;
	}
}

@media (max-width: 998px) {

  .sgl-view-all .sgl-title-section h2 span{

    font-size:17px;
  }

  .sgl-view-all .sgl-title-section h1 span {
    font-size: 22px;
}

  .sgl-view-all .sgl-title-section {
    padding: 15px;
}
	
}

@media (max-width: 1633px) {


  .sgl-view-all .sgl-title-section h2 span{

    font-size:17px;
  }

	
}

@media (max-width: 520px) {
	.sgl-view-all .card-deck {
		grid-template-columns: repeat(1, 1fr) !important;
		grid-auto-rows: minmax(min-content, max-content);
	}
  .sgl-view-all .sgl-title-section h2 span{

    font-size:17px;
  }

  .sgl-view-all .sgl-title-section h1 span {
    font-size: 22px;
}

  .sgl-view-all .sgl-title-section {
    padding: 15px;
}

}