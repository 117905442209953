.popup-card-deck .popup-card-item .popup-card .popup-card-body {
  font-size: 14px;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  input[type="text"],
input[type="password"],
.intl-tel-input input[type="tel"] {
  -webkit-box-sizing: border-box;
  color: black !important;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 0 0.75em;
  height: 2.2em;
  font-size: 0.97em;
  border-radius: 0;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  color: #333;
  -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  -o-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  font-weight: normal;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  .login-div-responsive {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 387px;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body .div-responsive {
  border: 1px solid #ccc;
  /* margin-bottom: 20px; */
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  /* width: 100%; */
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body input {
  margin-top: 10px;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  input::placeholder {
  font-size: 14px;
}
.popup-card-deck .popup-card-item .popup-card .popup-card-body .credit-company {
  color: #046240;
}

.popup-card-deck .popup-card-item .popup-card .copyright-text {
  font-size: 12px;
  text-align: center;
  color: black;
}
.popup-card-deck .popup-card-item .popup-card .popup-card-body .register_link {
  float: right;
  line-height: 1.8;
  color: #c2571a;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  .forgot_password_link {
  line-height: 1.8;
  color: #c2571a;
}

.popup-card-deck .popup-card-item .popup-card a {
  /* font-size: 14px; */
  color: #c2571a;
}
.popup-card-deck .popup-card-item .popup-card .popup-card-body #login_errors {
  padding-top: 10px;
  color: red;
  margin: 0px;
}

.popup-card-deck .popup-card-item .popup-card .reg_error_summary {
  color: red;
  margin-top: 10px;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body .btn {
  width: 90px;
  background-color: rgb(40, 94, 60);
  border-color: rgb(40, 94, 60);
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body .cancelBtn {
  width: 90px;
  border-color: rgb(40, 94, 60);
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #046240;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.popup-card-deck .popup-card-item .popup-card p {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body .half {
  width: 48%;
  float: left;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body .full {
  width: 100%;
  float: left;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body #mobile-verified {
  display: none;
  color: green;
  float: right;
}


.popup-card-deck .popup-card-item .popup-card .popup-card-body #mobile-verified img {
  width: 16px;
  height: 16px;
  margin-top: -1px;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body #verify_mobile-please-wait {
  display: none;
  float: right;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body #telephone-input {
  text-indent: 40px;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  .half:last-child {
  margin-left: 4%;
}

.popup-card-deck .popup-card-item .popup-card #verifyMobile_btn {
  margin-top: 5px;
  float: right;
}

/* Make Country list dropdown width match the input field width */
body .intl-tel-input .country-list {
  overflow-x: hidden;
  top: -199px;
  width: 100%;
}

.popup-card-deck .popup-card-item .popup-card #please-wait img {
  margin-top: 12px;
}

.popup-card-deck
  .popup-card-item
  .popup-card
  .popup-card-body
  .sgl-plugin-error-message {
  color: green;
  text-align: center;
}

.popup-card-deck .popup-card-item .popup-card .required {
  margin-top: 6px;
  color: #eb340a;
}

.popup-card-deck .popup-card-item .bar {
  height: 6px;
  background-color: rgb(40, 94, 60);
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: block;
  z-index: 999;
}

.popup-card-deck .popup-card-item .popup-card #sgl-plugin-success-message {
  display: none;
  text-align: center;
}

.popup-card-deck .popup-card-item .popup-card {
  position: relative;
  word-wrap: break-word;
  /* border: 1px solid rgba(0, 0, 0, .125); */
  min-height: 1px;
  height: 100% !important;
  transition: 0.5s ease;
  border-radius: 0;
  max-width: 100%;
  overflow: hidden;
  min-width: 215px;
  cursor: initial;
}

.popup-card-deck .popup-card-item .popup-card a {
  text-decoration: none;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-header {
  padding: 16px 15px 10px 15px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-radius: 0;
  transition: 0.5s ease;
  background-color: #fbfaf6;
  display: block;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-title {
  padding: 0px 20px 0px 0px;
  margin: 0;
  text-align: center;
  color: #333 !important;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-title a {
  color: #333 !important;
  font-size: 20px;
  text-transform: uppercase;
}

.popup-card-deck .popup-card-item .popup-card .popup-card-body {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 10px 15px;
  background: #fff;
}
.popup-card-deck .popup-card-item .popup-card * {
  box-sizing: border-box;
}
@media all and (max-width: 500px) {
  .popup-card-deck .half {
    width: 100% !important;
    float: none;
    margin-left: 0% !important;
  }

  .popup-card-deck .half:first-child {
    margin-top: 0px;
  }

  .popup-card-deck .half:last-child {
    margin-left: 0px;
  }

  .popup-card-deck .full.terms {
    text-align: center;
  }

  .popup-card-deck .full.terms a {
    display: block;
    text-align: center;
  }

  .popup-card-deck .full.submit .popup-card-deck .button {
    margin: 0px auto;
    float: none;
    display: block;
    /* background-color: #88201b; */
  }

  .popup-card-deck .reg_message {
    text-align: center;
  }

  .popup-card-deck .popup-card-item .popup-card .reg_error_summary {
    text-align: center;
  }

  .close_terms_cond a {
    width: 30px;
    height: 30px;
  }
}

.otp_error_summary {
  color: red;
  font-size: 13px;
  display: none;
}

#verify_otp-please-wait {
  min-height: 2.5em;
  line-height: 2.4em;
  display: none;
}

.terms_cond_overlay {
  display: none;
  position: fixed !important;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.9;
  opacity: 0.9;
  filter: alpha(opacity=90);
}

.close_terms_cond {
  top: -1%;
  right: 2%;
  position: absolute;
  z-index: 9999;
}

.close_terms_cond a {
  display: block;
  cursor: pointer;
}

.terms_and_conditions_cont {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 3%;
  padding: 1em;
  z-index: 1002;
  left: 0px;
}

.terms_and_conditions_cont .terms_cond_header {
  text-align: center;
}

.terms_and_conditions_cont .terms_cond_header h2 {
  color: #eee;
}

.terms_and_conditions_cont textarea {
  background-color: transparent;
  color: #eeeeee !important;
  width: 93%;
  resize: none;
  display: unset;
  -webkit-user-select: none;
  display: block;
  margin: 0px auto;
  padding-left: 1.7%;
  height: 90%;
  font-size: 14px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  white-space: pre-wrap;
  overflow-y: scroll;
  text-align: justify;
}

.popup-card-deck .popup-card-item .popup-card .desktop-header {
  display: block;
}
