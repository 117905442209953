.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ReactModal__Overlay{
  background-color: rgba(100, 100, 100, 0.75) !important;
  z-index: 99999 !important;
}

body.ReactModal__Body--open{
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

/* .ReactModal__Content{ 
  width: 75%;
  height: 75%;
  overflow-x: hidden;
  border-radius: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  border: 1px solid #ccc;
  background: #fff; 
  overflow: auto;
  overflow-x: auto;
  border-radius: 0px !important;
  outline: none;
  inset: 50% !important;
} */

.ReactModal__Content .jwplayer.jw-stretch-uniform .jw-media video{
  object-fit: contain !important;
}

.ReactModal__Content .jwplayer.jw-flag-aspect-mode{
  width: 100% !important;
  height:100% !important;
}

.ReactModal__Content .jwplayer .jw-title{
  width:100%;
  display:block !important;
  background:rgba(0,0,0,0.8);
  padding: 15px 45px 15px 15px;
  font-size:16px;
}

.ReactModal__Content .geg  .jwplayer .jw-title{
  background:rgba(40,94,60,0.8);
}

.ReactModal__Content .coth  .jwplayer .jw-title{
  background:rgba(62,39,45,0.8);
}

.ReactModal__Content .jw-title-primary{
  padding:0px;
  font-size:16px;
  line-height:18px;
}

.jw-player-wrapper{
  position: absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  border:3px solid #fff;
}

.jw-player-wrapper.geg .jwplayer *{
  font-family: Freigeist,sans-serif;
}

.jw-player-wrapper.coth .jwplayer *{
  font-family: "Playfair Display";
}

.sgl-close-modal{
  font-size: 30px;
  line-height:29px;
  position: absolute;
  top: 3px;
  right: 3px;
  color: #fff;
  font-weight: bold;
  display: block;
  cursor: pointer;
  text-decoration: none;
  z-index: 999;
  padding: 4px;
  height: auto;
}

.sgl-close-modal .cross-btn-img{
  height:30px;
  width:30px;
}

/* @media (max-width: 875px) {
  .ReactModal__Content {
    width: 95%;
  }
} */

@media (max-width: 767px) {
  .sgl-close-modal{
    font-size: 20px;
    line-height:20px;
  }

  .ReactModal__Content .jwplayer .jw-title{
    font-size:16px !important;
  }

  .ReactModal__Content .jw-title-primary{
    font-size:16px !important;
    line-height:18px !important;
  }
}

@media (max-width: 600px){
  .ReactModal__Content{
    /* height: 90%; */
    width: 100% !important;
    /* background: black !important; */
  }

  .ReactModal__Content .jwplayer .jw-title{
    position: absolute;
    bottom: 60px;
    top: auto;
    text-align: center;
    padding: 20px !important;
    background: none !important;
  }

  .ReactModal__Content .jw-title-primary{
    white-space: normal;
    text-transform: uppercase;
    line-height: 24px !important;
  }

  .jw-player-wrapper{
    border:none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
