@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/*sgl-videos-scroller section*/
section.sgl-videos-scroller {
    /* font-family: "Lato", sans-serif; */
    padding: 30px 0 20px;
    background: #fafafa;
    /* border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0; */
    line-height: 100%;
    font-size: 100%;
    -moz-osx-font-smoothing: auto;
    margin:0px;
}

section.sgl-videos-scroller .owl-carousel {
    margin-left: -15px;
    margin-right: -15px;
}

section.sgl-videos-scroller .owl-carousel .sgl-item {
    padding: 0 15px;
}

section.sgl-videos-scroller .owl-carousel .sgl-post {
    margin-bottom: 15px;
}

section.sgl-videos-scroller .owl-carousel .owl-wrapper-outer {
    padding: 3px 0;
}

section.sgl-videos-scroller .owl-theme .owl-controls .owl-pagination {
    display: none !important;
}

section.sgl-videos-scroller .owl-carousel {
    width: auto;
}

section.sgl-videos-scroller.coth .owl-carousel {
    display: block;
}

section.sgl-videos-scroller .owl-theme .owl-controls {
    margin-top: 20px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -64px;
    margin-right: 2px;
}

section.sgl-videos-scroller .owl-theme .owl-controls {
    margin-right: 12px;
}

section.sgl-videos-scroller .owl-theme .owl-controls button {
    font-size: 15px !important;
    color: #999999;
    width: 20px !important;
    height: 20px !important;
    line-height: 18px;
    text-align: center;
    background: transparent !important;
    border: 1px solid #cccccc;
    margin-left: 2px;
    outline: none;
    padding: 0 !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    opacity: 1;
    margin: 0 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    cursor: pointer;
    min-height: auto !important;
    font-family: Verdana, sans-serif !important;
    font-weight: normal !important;
}

section.sgl-videos-scroller .owl-theme .owl-controls button:hover {
    color: #ffffff !important;
    background: #f44336 !important;
    border: 1px solid #f44336 !important;
}

section.sgl-videos-scroller .owl-theme .owl-controls button.owl-prev:after {
    /* content: '\f104';
    font-family: 'FontAwesome'; */
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #999999;
}

section.sgl-videos-scroller .owl-theme .owl-controls button.owl-next:after {
    /* content: '\f105';
    font-family: 'FontAwesome'; */
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #999999;
}

section.sgl-videos-scroller .owl-theme .owl-controls button.owl-prev:hover:after {
    color: #ffffff;
}

section.sgl-videos-scroller .owl-theme .owl-controls button.owl-next:hover:after {
    color: #ffffff;
}

section.sgl-videos-scroller{
    overflow: hidden;
}

section.sgl-videos-scroller .sgl-videos-scroller-box {
    position: relative;
}

section.sgl-videos-scroller .sgl-videos-scroller-box:before {
    position: absolute;
    content: '';
    width: 10000px;
    height: 100%;
    background: rgba(250, 250, 250, 0.7);
    right: 100%;
    top: 0;
    z-index: 2;
}

section.sgl-videos-scroller .sgl-videos-scroller-box:after {
    position: absolute;
    content: '';
    width: 10000px;
    height: 100%;
    background: rgba(250, 250, 250, 0.7);
    left: 100%;
    margin-left: 3px;
    top: 0;
}

section.sgl-videos-scroller .owl-carousel .owl-wrapper-outer {
    overflow: visible;
}

section.sgl-videos-scroller .sgl-post {
    position: relative;
}

section.sgl-videos-scroller .sgl-post .sgl-post-gallery {
    position: relative;
    cursor: pointer;
}

section.sgl-videos-scroller .sgl-post img {
    width: 100%;
}

section.sgl-videos-scroller .sgl-post a.sgl-category-post {
    position: absolute;
    bottom: 10px;
    left: 20px;
}

section.sgl-videos-scroller a.sgl-category-post {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #ffffff;
    font-size: 11px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 400;
    text-transform: uppercase;
    padding: 2px 10px;
    background: #f44336;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
}

section.sgl-videos-scroller a.sgl-category-post:hover {
    /* opacity: 0.7; */
}

section.sgl-videos-scroller a.sgl-category-post {
    background: #f58021;
}

section.sgl-videos-scroller ul.sgl-post-tags {
    margin: 0 !important;
    padding: 0 !important;
}

section.sgl-videos-scroller ul.sgl-post-tags li {
    display: block;
    margin-right: 7px;
    color: #999999;
    font-size: 12px;
    /* font-family: 'Lato', sans-serif; */
    font-style: italic;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    margin-bottom: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
}

section.sgl-videos-scroller ul.sgl-post-tags li i {
    color: #ccc;
    margin-right: 9px;
}

section.sgl-videos-scroller ul.sgl-post-tags li a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #999999;
}

section.sgl-videos-scroller ul.sgl-post-tags li a:hover {
    color: #f44336;
}

section.sgl-videos-scroller ul.sgl-post-tags li:last-child {
    margin-right: 0;
}

section.sgl-videos-scroller .sgl-standard-post {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content {
    padding-top: 5px;
}

section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content {
    padding-top: 10px;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content h2 {
    color: #333333;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content h2 a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #333333;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content h2 a:hover {
    color: #f44336;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content-1 {
    padding-top: 10px;
    width: 100%;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content-1 h2 {
    color: #333333;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content-1 h2 a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #333333;
}

section.sgl-videos-scroller .sgl-standard-post .sgl-post-content h2 a:hover {
    color: #f44336;
}

section.sgl-videos-scroller .sgl-title-section {
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 30px;
}

section.sgl-videos-scroller .sgl-title-section h1 {
    color: #222222;
    font-size: 15px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    margin: 0 0 30px;
    text-transform: uppercase;
    margin-bottom: -1px;
}

section.sgl-videos-scroller .sgl-title-section h1 span {
    display: inline-block;
    padding-bottom: 16px;
    padding-right: 5px;
    border-bottom: 1px solid #f44336;
}

section.sgl-videos-scroller .sgl-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

section.sgl-videos-scroller .sgl-post-gallery .sgl-play {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

section.sgl-videos-scroller .sgl-post-gallery .sgl-play .sgl-play-icon {
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.6);
    position: absolute;
    align-items: center;
    font-size: 18px;
    display: flex;
    color: #fff;
    margin-top: -20px;
    margin-left: -20px;
    text-indent: 4px;
}

section.sgl-videos-scroller .sgl-post-gallery .sgl-play span {
    display: inline-block;
    margin: 0px auto;
}

section.sgl-videos-scroller.coth .sgl-video-state{
    font-family: "Work Sans", sans-serif;
}

section.sgl-videos-scroller .sgl-video-state{
    font-weight: bold;
    position: absolute;
    top:5px;
    right:5px;
    color:#fff;
    background-color:#222;
    font-size:12px;
    padding:5px 10px 3px 10px;
    border:1px solid #aaa;
    line-height:12px;
    border-radius: 3px;
    text-shadow: none;
    letter-spacing: 0.6px;
}
section.sgl-videos-scroller .sgl-post-gallery .sgl-play-1 .sgl-play-icon-1 {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    background: hsla(0,0%,86%,.6);
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 24px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    text-indent: 3px;
    top: 50%;
    width: 50px;
    
}

section.sgl-videos-scroller .sgl-post-gallery .sgl-play-1 span {
    display: inline-block;
    margin: 0 auto;
    
}


section.sgl-videos-scroller.geg,
section.sgl-videos-scroller.coth{
    border:none;
    background:none;
    padding:25px 0px 80px;
}

section.sgl-videos-scroller.geg * {
    font-family: Freigeist,sans-serif;
}

section.sgl-videos-scroller.coth * {
    font-family: Playfair Display;
}

section.sgl-videos-scroller.geg .sgl-container{
    width:auto;
    padding-left:3.5%;
    padding-right:3.5%;
}

section.sgl-videos-scroller.coth .sgl-container{
    width:auto;
    padding-left:0px;
    padding-right:0px;
}

section.sgl-videos-scroller.geg  ul.sgl-post-tags,
section.sgl-videos-scroller.coth  ul.sgl-post-tags {
    display:none;
}

section.sgl-videos-scroller.geg .sgl-title-section,
section.sgl-videos-scroller.coth .sgl-title-section{
    border:none;
}

section.sgl-videos-scroller.geg .sgl-title-section h1,
section.sgl-videos-scroller.coth .sgl-title-section h1{
    margin:0px;
    border:none;
    font-size:19px;
    line-height:24px;
}

section.sgl-videos-scroller.geg .sgl-title-section h1 span{
    padding:0px;
    border:none;
    color:#000;
    text-transform: capitalize;
    font-size:20px;
}

section.sgl-videos-scroller.coth .sgl-title-section h1 span{
    padding:0px;
    border:none;
    color:#000;
    text-transform: capitalize;
    font-size:26px;
}

section.sgl-videos-scroller.geg .sgl-title-section h1 span:after,
section.sgl-videos-scroller.coth .sgl-title-section h1 span:after{
    content: "\2192";
    padding-left:10px;
    font-size: 16px;
    position: relative;
    top: -3px;
}

section.sgl-videos-scroller.geg .sgl-standard-post .sgl-post-content h2,
section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content h2{
    font-size:15px;
}

section.sgl-videos-scroller.geg .sgl-standard-post .sgl-post-content h2 a{
    text-transform: capitalize;
    color:#000;
    font-size: 15px;
    cursor:text;
}

section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content h2 a{
    text-transform: capitalize;
    color:#000;
    font-size: 19px;
    cursor:text;
}

section.sgl-videos-scroller.coth .sgl-standard-post .sgl-post-content p{
    font-family: "Work Sans", sans-serif;
    font-size: 15px
}

section.sgl-videos-scroller.geg .owl-carousel,
section.sgl-videos-scroller.coth .owl-carousel{
    margin-right:0px;
}

section.sgl-videos-scroller.geg .owl-carousel .sgl-item:last-child,
section.sgl-videos-scroller.coth .owl-carousel .sgl-item:last-child{
    padding-right:0px;
}

section.sgl-videos-scroller.geg  .owl-theme .owl-controls,
section.sgl-videos-scroller.coth  .owl-theme .owl-controls{
    bottom: -20px;
    top:auto;
    margin:0px !important;
}

section.sgl-videos-scroller.geg  .sgl-videos-scroller-box::after,
section.sgl-videos-scroller.geg  .sgl-videos-scroller-box::before,
section.sgl-videos-scroller.coth  .sgl-videos-scroller-box::after,
section.sgl-videos-scroller.coth  .sgl-videos-scroller-box::before{
    background:#fff;
}


section.sgl-videos-scroller.geg .owl-theme .owl-controls button,
section.sgl-videos-scroller.coth .owl-theme .owl-controls button{
    border-radius: 0px;
    color: #000;
    border: 3px solid #000;
    height: 25px !important;
    width: 25px !important;
    font-size: 28px !important;
    line-height: 16px;
    padding-bottom: 2px !important;
    border-radius: 2px;
}

section.sgl-videos-scroller.geg .owl-theme .owl-controls button:hover,
section.sgl-videos-scroller.coth .owl-theme .owl-controls button:hover{
    color: #ffffff !important;
    background: #000 !important;
    border: 1px solid #000 !important;
}

section.sgl-videos-scroller.geg .sgl-title-section,
section.sgl-videos-scroller.coth .sgl-title-section{
    margin-bottom:40px;
}

section.sgl-videos-scroller.geg .owl-theme .owl-controls button span,
section.sgl-videos-scroller.coth .owl-theme .owl-controls button span{
    top:-1px;
    position:relative;
    font-family: "Lato", sans-serif;
    font-weight: bold;
}

section.sgl-videos-scroller.geg .sgl-see-all{
    position: absolute;
    right: 0px;
    bottom: -70px;
    background: #e0d8c7;
    display: block;
    padding: 11px 24px;
    border-radius: 14px;
    text-decoration: none;
    font-size: 15px;
    text-transform: capitalize;
    color:#000;
    font-weight:bold;
}

section.sgl-videos-scroller.coth .sgl-see-all{
    position: absolute;
    right: 0px;
    bottom: -70px;
    background: #873F41;
    display: block;
    padding: 11px 24px;
    border-radius: 14px;
    text-decoration: none;
    font-size: 15px;
    text-transform: capitalize;
    color:#ffffff;
    font-weight:bold;
    font-family: "Work Sans", sans-serif;
}

@media (max-width: 600px) {
    section.sgl-videos-scroller .sgl-container {
        width: auto;
    }

    section.sgl-videos-scroller.geg .owl-theme .owl-controls,
    section.sgl-videos-scroller.coth .owl-theme .owl-controls{
        margin: 0px auto !important;
        left: 15px;
    }

    section.sgl-videos-scroller.geg .sgl-see-all,
    section.sgl-videos-scroller.coth .sgl-see-all{
        right:auto;
        left:50%;
        transform:translateX(-50%);
    }
}



/*sgl-hero-area section*/
section.sgl-big-slider {
    padding: 20px 0 25px;
    background: #fff;
    overflow: hidden;
    line-height: 1.42857143;
}

section.sgl-big-slider * {
    box-sizing: border-box;
}

section.sgl-big-slider .bx-wrapper .bx-pager {
    position: relative;
    width: 100%;
    top: inherit;
    right: inherit;
    text-align: center;
    margin-top: 0px;
}

section.sgl-big-slider .bx-wrapper .bx-pager.bx-default-pager a {
    border-color: #d9d9d9;
}

section.sgl-big-slider .bx-wrapper .bx-pager.bx-default-pager a.active {
    border-color: #f44336;
    background: #f44336;
}

section.sgl-big-slider .bx-viewport {
    overflow: visible !important;
    margin-left: -5px;
    margin-right: -5px;
    width: auto !important;
}

section.sgl-big-slider .sgl-big-bxslider li {
    padding: 0 5px;
}

section.sgl-big-slider .sgl-big-bxslider li .sgl-image-post2 .sgl-hover-box .sgl-inner-hover h2 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
    max-height: 72px;
    max-width: 880px;
    margin: 10px auto;
}


section.sgl-big-slider .sgl-image-slider {
    width: 100%;
}

section.sgl-big-slider .sgl-image-slider ul {
    padding: 0;
    margin: 0;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-viewport {
    background: transparent;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-pager,
section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-controls-auto {
    bottom: inherit;
    top: 30px;
    right: 30px;
    width: auto;
    padding-top: 0;
}

section.sgl-big-slider .sgl-image-slider .bx-controls-direction {
    position: absolute;
    width: 100%;
    top: 50%;
    /* margin-top: -35px; */
    height: 0px;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    left: 30px;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev:hover,
section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next:hover {
    background: #ececec;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev:hover:before,
section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next:hover:before {
    color: #333333;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    right: 30px;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev:before {
    margin-top: -23px;
    margin-left: -13px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next:before {
    margin-top: -23px;
    margin-left: -8px;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.sgl-big-slider .sgl-image-slider .image-post {
    width: 100%;
}

section.sgl-big-slider .sgl-image-slider .image-post .sgl-hover-box {
    bottom: 0px;
}

section.sgl-big-slider .sgl-image-slider .image-post .sgl-hover-box .sgl-inner-hover h2 {
    font-size: 30px;
    font-weight: 900;
    line-height: 36px;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box {
    background: transparent;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover {
    background: rgba(17, 17, 17, 0) !important;
    background: -moz-linear-gradient(top, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.1) 10%, rgba(17, 17, 17, 0.95) 100%) !important;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(17, 17, 17, 0)), color-stop(10%, rgba(17, 17, 17, 0.1)), color-stop(100%, rgba(17, 17, 17, 0.95))) !important;
    background: -webkit-linear-gradient(top, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.1) 10%, rgba(17, 17, 17, 0.95) 100%) !important;
    background: -o-linear-gradient(top, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.1) 10%, rgba(17, 17, 17, 0.95) 100%) !important;
    background: -ms-linear-gradient(top, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.1) 10%, rgba(17, 17, 17, 0.95) 100%) !important;
    background: linear-gradient(to bottom, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.1) 10%, rgba(17, 17, 17, 0.95) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#171717', endColorstr='#171717', GradientType=0) !important;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover h2 a {
    color: #ffffff !important;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover h2 a:hover {
    opacity: 0.7;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover ul.sgl-post-tags li {
    color: #ffffff;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover ul.sgl-post-tags li a {
    color: #ffffff;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover ul.sgl-post-tags li a:hover {
    opacity: 0.7;
}

section.sgl-big-slider .sgl-image-slider .image-post:hover .sgl-hover-box .sgl-inner-hover p {
    color: #ffffff;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    display: block;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box .sgl-inner-hover {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    padding: 10px 50px;
    text-align: center;
    margin-top: -40px;
    background: rgba(51, 51, 51, 0.5)
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box a.sgl-category-post {
    position: relative;
    bottom: inherit;
    left: inherit;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box h2 {
    color: #333333;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 7px;
    margin-top: 10px;
    max-height: 48px;
    overflow: hidden;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box h2 a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #ffffff;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box ul.sgl-post-tags li {
    color: #fff;
    font-size: 15px;
    font-weight: bold;
}

section.sgl-big-slider .sgl-image-post2 .sgl-hover-box ul.sgl-post-tags li a:hover {
    color: #f44336;
}

section.sgl-big-slider .sgl-image-post2 div.post-content {
    padding: 16px 16px 0;
}

section.sgl-big-slider .sgl-image-post2 div.post-content p {
    margin-bottom: 3px;
}

section.sgl-big-slider .sgl-image-post2 div.post-content p a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #f44336;
    text-decoration: underline;
}

section.sgl-big-slider .sgl-image-post2 div.post-content p a:hover {
    opacity: 0.7;
}

section.sgl-big-slider .sgl-image-post2 div.post-content>a {
    margin-top: 12px;
}

section.sgl-big-slider .sgl-news-post {
    position: relative;
    border: 1px solid #ccc;
    padding: 10px;
    background: #eee;
}

section.sgl-big-slider .sgl-news-post .sgl-post-gallery {
    position: relative;
}

section.sgl-big-slider .sgl-news-post img {
    width: 100%;
}

section.sgl-big-slider .sgl-news-post a.sgl-category-post {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

section.sgl-big-slider a.sgl-category-post {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #ffffff;
    font-size: 11px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    text-transform: uppercase;
    padding: 4px 10px;
    background: #f44336;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    background: #f58021;
}


section.sgl-big-slider ul.sgl-post-tags {
    margin: 0;
    padding: 0;
}

section.sgl-big-slider ul.sgl-post-tags li {
    display: inline-block;
    margin-right: 7px;
    color: #999999;
    font-size: 12px;
    /* font-family: 'Lato', sans-serif; */
    font-style: italic;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

section.sgl-big-slider ul.sgl-post-tags li i {
    color: #ccc;
    margin-right: 9px;
}

section.sgl-big-slider ul.sgl-post-tags li a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #999999;
}

section.sgl-big-slider ul.sgl-post-tags li a:hover {
    color: #f44336;
}

section.sgl-big-slider ul.sgl-post-tags li:last-child {
    margin-right: 0;
}

section.sgl-big-slider .sgl-container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 80%
}

.sgl-vertical-slider .bx-viewport {
    background: none;
}

.sgl-vertical-slider.sgl-column .bx-viewport ul li {
    width: 100% !important;
    overflow: hidden;
    margin-bottom:10px;
}

.sgl-vertical-slider.sgl-column .bx-wrapper .bx-prev {
    left: calc(100% - 31px) !important;
    top: -1px !important;
    transform: rotate(90deg);
    height: 32px;
    background: rgba(255,255,255,0.8);
    width: 30px;
}

.sgl-vertical-slider.sgl-column .bx-wrapper .bx-prev:before{
    margin-top: -9.5px;
    margin-left: -10px;
    color: #666;
    font-size: 27px;
}

.sgl-vertical-slider.sgl-column .bx-wrapper .bx-next {
    right: 0px !important;
    bottom: 0px !important;
    transform: rotate(90deg);
    height: 32px;
    top: auto;
    background: rgba(255,255,255,0.8);
    width: 30px;
}

.sgl-vertical-slider.sgl-column .bx-wrapper .bx-next:before {
    margin-top: -9.5px;
    margin-left: -8px;
    color: #666;
    font-size: 27px;
}

.sgl-vertical-scroll .sgl-scroll-wrapper{
    overflow-y: scroll;
}

.sgl-vertical-scroll .sgl-scroll-wrapper .sgl-row{
    margin-bottom:15px;
}

.sgl-vertical-scroll .sgl-scroll-wrapper .sgl-row p{
    margin-top:3px;
}

@media (max-width: 600px) {
    section.sgl-big-slider .sgl-container {
        width: auto;
    }
}

@media (max-width: 767px) {
    section.sgl-big-slider .sgl-big-bxslider li .sgl-image-post2 .sgl-hover-box .sgl-inner-hover h2 {
        font-size: 20px;
        line-height: 26px;
        max-height: 52px;
    }

    section.sgl-big-slider .sgl-big-bxslider li .sgl-image-post2 .sgl-hover-box .sgl-inner-hover ul.sgl-post-tags {
        /* display: none; */
    }

    section.sgl-big-slider .sgl-image-slider .sgl-image-post2 .sgl-hover-box h2 {
        font-size: 15px;
        line-height: 18px;
        max-height: 36px;
    }

    section.sgl-big-slider .bx-pager {
        display: none;
    }

    section.sgl-videos-scroller.geg .sgl-container {
        padding-left:6.5%;
        padding-right:6.5%;
    }
}

@media (max-width: 500px) {
    section.sgl-big-slider .sgl-image-post2 .sgl-hover-box .sgl-inner-hover {
        padding: 10px 30px;
    }

    section.sgl-big-slider .sgl-big-bxslider li .sgl-image-post2 .sgl-hover-box .sgl-inner-hover h2,
    section.sgl-big-slider .sgl-image-post2 .sgl-hover-box h2 {
        font-size: 18px;
        line-height: 18px;
        max-height: 36px;
    }

    section.sgl-big-slider .sgl-image-slider .image-post .sgl-hover-box .sgl-inner-hover h2 {
        font-size: 18px;
        line-height: 20px;
    }

    section.sgl-big-slider .sgl-image-slider .sgl-image-post2 .sgl-hover-box ul.sgl-post-tags,
    section.sgl-big-slider .sgl-image-post2 .sgl-hover-box ul.sgl-post-tags {
        /* display: none; */
    }

    section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev {
        width: 30px;
        height: 30px;
        left: 15px;
    }

    section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-prev:before {
        font-size: 24px;
        margin-top: -17px;
        margin-left: -10px;
    }

    section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next {
        width: 30px;
        height: 30px;
        right: 15px;
    }

    section.sgl-big-slider .sgl-image-slider .bx-wrapper .bx-next:before {
        font-size: 24px;
        margin-top: -17px;
        margin-left: -6px;
    }

    section.sgl-big-slider .sgl-image-slider .bx-controls-direction {
        /* margin-top: -20px; */
    }
}




.sgl-hero-area {
    display: flex;
    /* font-family: "Lato", sans-serif; */
    padding: 15px 20px;
    background: #fafafa;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    line-height: 100%;
    font-size: 100%;
    margin-bottom: 20px;
    background: #285E3C;
    flex-direction: row;
    margin:0px;
}

.sgl-hero-area:after {
    content: "";
    display: table;
    clear: both;
}

.sgl-hero-area * {
    /* font-family: "Lato", sans-serif; */
}


.sgl-hero-area .sgl-video-state{
    font-weight: bold;
    position: absolute;
    top:5px;
    right:5px;
    color:#fff;
    background-color:#222;
    font-size:12px;
    padding:5px 10px 3px 10px;
    border:1px solid #aaa;
    line-height:12px;
    border-radius: 3px;
    text-shadow: none;
    letter-spacing: 0.6px;
    font-style:normal;
}

.sgl-hero-area .sgl-column:first-child {
    flex: 68%;
    padding-right: 2%;
}

.sgl-hero-area .sgl-column:first-child .sgl-row{
    margin-bottom:15px;
}

.sgl-hero-area .sgl-column:last-child {
    flex: 30%;
}

.sgl-hero-area .sgl-column img {
    max-width: 100%;
    height: auto;
    width: 100%;
}

.sgl-hero-area .sgl-column .sgl-heading {
    border-bottom: 1px solid #fff;
    margin-bottom: 16px;
}

.sgl-hero-area .sgl-column h1 {
    font-size: 16px;
    margin: 0px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: -1px;
}

.sgl-hero-area .sgl-column h1 span {
    display: inline-block;
    padding-bottom: 10px;
    padding-right: 5px;
    border-bottom: 1px solid #f44336;
    margin-top: 1px;
}

.sgl-hero-area .sgl-column p {
    color: #fff;
    font-size: 14px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    -moz-osx-font-smoothing: auto;
    margin: 0px;
    margin-top: 8px;
    text-transform: uppercase;
    font-style: normal;
}

.sgl-hero-area .sgl-column ul {
    margin: 0 !important;
    padding: 0 !important;
}

.sgl-hero-area .sgl-column ul li {
    display: block;
    color: #ccc;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-style: italic;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    margin-bottom: 3px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 !important;
    font-weight: bold;
}

.sgl-hero-area .sgl-row {
    margin-bottom: 0px;
    display:block !important;
}

.sgl-hero-area .sgl-row .sgl-img {
    position: relative;
}

.sgl-hero-area .sgl-row .sgl-img .sgl-play {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.sgl-hero-area .sgl-row .sgl-img .sgl-play .sgl-play-icon {
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.6);
    position: absolute;
    align-items: center;
    font-size: 18px;
    display: flex;
    color: #fff;
    margin-top: -20px;
    margin-left: -20px;
    text-indent: 4px;
}

.sgl-hero-area .sgl-row .sgl-img .sgl-play .sgl-play-icon-large {
    height: 80px;
    width: 80px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.6);
    position: absolute;
    align-items: center;
    font-size: 32px;
    display: flex;
    color: #fff;
    margin-top: -40px;
    margin-left: -40px;
    text-indent: 8px;
}


.sgl-hero-area .sgl-row .sgl-img .sgl-play span {
    display: inline-block;
    margin: 0px auto;
}

.sgl-hero-area.coth{
    padding: 20px 3.5% 10px 3.5%;
}

.sgl-hero-area.coth{
    padding: 20px 0px 10px 0px;
}

.sgl-hero-area.geg *{
    font-family: Freigeist,sans-serif;
}

.sgl-hero-area.coth *{
    font-family: "Playfair Display";
}

.sgl-hero-area.geg .sgl-column .sgl-heading,
.sgl-hero-area.coth .sgl-column .sgl-heading{
    border:none;
    margin-bottom:6px;
}

.sgl-hero-area.geg .sgl-column h1 span,
.sgl-hero-area.coth .sgl-column h1 span{
    border:none;
}

.sgl-hero-area.geg .sgl-column.must-see h1{
    font-size:30px;
    text-transform: capitalize;
}

.sgl-hero-area.coth .sgl-column.must-see h1{
    font-size:34px;
    text-transform: capitalize;
}

.sgl-hero-area.geg .sgl-column.featured h1{
    font-size: 28px;
    margin-bottom: 7px;
    text-transform: capitalize;
}

.sgl-hero-area.coth .sgl-column.featured h1{
    font-size: 32px;
    margin-bottom: 7px;
    text-transform: capitalize;
}

.sgl-hero-area.geg .sgl-row,
.sgl-hero-area.coth .sgl-row {
    margin-bottom:10px;
}

.sgl-hero-area.geg .sgl-row ul,
.sgl-hero-area.coth .sgl-row ul{
    display:none;
}

.sgl-hero-area.geg .sgl-row ul li,
.sgl-hero-area.coth .sgl-row ul li{
    margin-bottom:0px;
}

.sgl-hero-area.geg .sgl-column.must-see .sgl-row p{
    font-size: 17px;
    text-transform: capitalize;
}

.sgl-hero-area.coth .sgl-column.must-see .sgl-row p{
    font-size: 18px;
    text-transform: capitalize;
}

.sgl-hero-area.geg .sgl-column.featured .sgl-row p{
    font-size: 14px;
    text-transform: capitalize;
}

.sgl-hero-area.coth .sgl-column.featured .sgl-row p{
    font-size: 17px;
    text-transform: capitalize;
}

@media (max-width: 767px) {

    .sgl-hero-area {
        display: flex;
        flex-direction: column;
    }

    .sgl-hero-area .sgl-column:first-child {
        flex: 100%;
        padding-right: 0%;
        margin-bottom:15px;
    }

    .sgl-hero-area .sgl-column:last-child {
        flex: 100%;
    }

    .sgl-hero-area .sgl-row .sgl-img .sgl-play .sgl-play-icon-large {
        height: 50px;
        width: 50px;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background: rgba(220, 220, 220, 0.6);
        position: absolute;
        align-items: center;
        font-size: 24px;
        display: flex;
        color: #fff;
        margin-top: -25px;
        margin-left: -25px;
        text-indent: 3px;
    }

    .sgl-hero-area.geg .sgl-column.must-see .sgl-row p{
        font-size:14px;
    }

    .sgl-hero-area.coth .sgl-column.must-see .sgl-row p{
        font-size:18px;
    }

    .sgl-hero-area.geg{
        padding: 20px 6.5% 10px 6.5%;
    }

}

.no-items-to-display{
    margin:0px;
    font-size:14px;
    font-weight:normal;
    color:#5F5E5E;
}

/* ERRORS */
.unauthorized{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
}