.mymodal .child_modal h2 {
  border-bottom: 1px solid #555;
  font-size: 18px !important;
  margin-top: 0.4em;
  padding-bottom: 5px;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.mymodal {
  box-sizing: border-box;
}

.mymodal .subscription_div_overflow {
  max-height: 100%;
  overflow: hidden;
}

.mymodal {
  background-color: #fff;
  color: #333;
}

.mymodal .sgl-label-border {
  border-bottom: 1px solid #555;
  font-size: 18px!important;
  margin-top: 0.4em;
  padding-bottom: 5px;
  text-align: center;
}

.mymodal button, .mymodal input,.mymodal select,.mymodal textarea{
  margin-bottom: 1em;
}

.mymodal .sgl-add_entry {
  color: #88201b !important;
}
.mymodal label,
.mymodal legend {
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin-bottom: 0.2em;

  color: #222;
}

.mymodal .flex-video-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mymodal .subscription_radios {
  background-color: #fafafa;
  border: 1px solid #bbafa0;
  float: left !important;
  margin: 2px !important;
  padding: 2px;
  width: 148px !important;
}

.mymodal .class_list_li {
  list-style-type: none !important;
}

.mymodal input[type="checkbox"],
.mymodal input[type="radio"] {
  display: inline;
  font-size: 16px;
  box-sizing: border-box;
  padding: 0;
  display: inline;
}

.mymodal button,
.mymodal input,
.mymodal select {
  overflow: visible;
  touch-action: manipulation;
}

.mymodal .sgl-mr-5 {
  margin-right: 5px !important;
}

.mymodal .font-16 {
  font-size: 16px;
}

.mymodal .desc_span > hr,
.mymodal .ios-hr {
  background: #d7d7d7;
  border: 3px;
  color: #d7d7d7;
  display: block;
  height: 1px;
  margin: 3px !important;
  opacity: 1;
  padding: 0 !important;
  width: 93%;
}

.mymodal .center-align {
  text-align: center !important;
}

.mymodal .sub_dates {
  color: green;
  font-weight: 700;
}

.mymodal .add_entry_custom_label {
  font-weight: inherit !important;
}

.mymodal .select-resize-ghost,
.mymodal .select2-container .select2-choice,
.mymodal .select2-container .select2-selection,
.mymodal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 stroke=%27%23333%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 class=%27feather feather-chevron-down%27%3E%3Cpolyline points=%276 9 12 15 18 9%27%3E%3C/polyline%3E%3C/svg%3E");
  background-position: right 0.45em top 50%;
  background-repeat: no-repeat;
  background-size: auto 16px;
  border-radius: 0;
  box-shadow: inset 0 -1.4em 1em 0 rgb(0 0 0 / 2%);
  display: block;
  padding-right: 1.4em;
  outline: none;
}

.mymodal .select-resize-ghost,
.mymodal .select2-container .select2-choice,
.mymodal .select2-container .select2-selection,
.mymodal input[type="date"],
.mymodal input[type="email"],
.mymodal input[type="number"],
.mymodal input[type="password"],
.mymodal input[type="search"],
.mymodal input[type="tel"],
.mymodal input[type="text"],
.mymodal input[type="url"],
.mymodal select,
.mymodal textarea {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit !important;
  color: #333;
  font-size: 0.97em;
  font-weight: 400;
  height: 2.507em;
  max-width: 100%;
  padding: 0 0.75em;
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  vertical-align: middle;
  width: 100%;
  outline: none;
}

.mymodal .input-height {
  height: 2em !important;
}
.mymodal .select-width,
.mymodal .sub-input-width {
  margin-bottom: 10px !important;
  max-width: 300px !important;
  width: 90% !important;
}

.mymodal button,
.mymodal select {
  text-transform: none;
}

.mymodal .mr-btm-5 {
  margin-bottom: 5px;
}

.mymodal input[type="checkbox"] + label,
.mymodal input[type="radio"] + label {
  display: inline;
}

.mymodal {
  font-size: 12px;
  line-height: 1.2;
}

.mymodal a {
  font-size: 12px;
}

.mymodal .continue-button-div {
  border-top: 1px solid #e4e4e4;
  clear: both;
  margin: 0;
  overflow: hidden;
  padding: 12px 0 0;
  margin-bottom: 10px;
}

.mymodal .sgl-button {
  color: #f7f7f7 !important;
  background-color: rgb(40, 94, 60) !important;
  font-weight: bold !important;
  min-height: 2em !important;
  height: 2em !important;
  font-size: 15px !important;
  border: none;
}

.mymodal .required {
  color: red !important;
}

.mymodal .left {
  float: left;
}

.mymodal button {
  cursor: pointer;
}

.mymodal .right {
  float: right;
  margin-right: 0;
}

.mymodal .sub-main-div {
  margin: 20px;
}
.mymodal .float-left {
  float: left;
}
.mymodal .close-right {
  float: right;
  margin: 1rem;
}
.mymodal .sub_button {
  margin: 1.2em !important;
}

.mymodal .font-16 {
  font-size: 16px;
}
.mymodal .in-iframe {
  width: 95% !important;
  height: 375px !important;
}
.mymodal .video-in-iframe {
  width: 95% !important;
  height: 375px !important;
}
.mymodal .subscription-modal {
  width: 60% !important;
  max-width: 750px !important;
}
.mymodal .subscription_radios {
  float: left !important;
  width: 148px !important;
  margin: 2px !important;
  padding: 2px;
  background-color: #fafafa;
  border: 1px solid #bbafa0;
}

.mymodal .ios-hr,
.mymodal .desc_span > hr {
  color: #d7d7d7;
  border: 3px;
  margin: 3px !important;
  padding: 0 !important;
  display: block;
  height: 1px;
  background: #d7d7d7;
  width: 93%;
  opacity: 1;
}
.mymodal .free_sub {
  color: green;
  font-weight: bold;
  font-style: italic;
}
.mymodal .disabled_sub {
  color: red;
  font-weight: bold;
  font-style: italic;
  clear: both;
  display: block;
}
.mymodal .sub_dates {
  color: green;
  font-weight: bold;
}
.mymodal .select-width {
  width: 90% !important;
  max-width: 300px !important;
  margin-bottom: 10px !important;
}
.mymodal .sub-input-width {
  width: 90% !important;
  max-width: 300px !important;
  margin-bottom: 10px !important;
}
.mymodal .close_player {
  position: fixed;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 99;
  display: block;
}

.mymodal p {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 0px;
}

.mymodal .right,
.right {
  float: right;
  margin-right: 2px;
  margin-left: 2px;
}

.mymodal .left {
  float: left;
}

.mymodal button {
  cursor: pointer;
}

.mymodal .terms_div {
  background: #f2f2f2 none repeat scroll 0 0;
  border: 1px solid #ccc;
  max-height: 100px;
  overflow: auto;
  padding: 6px;
}

.mymodal .trash-icon {
  float: right !important;
  cursor: pointer;
}
.mymodal .success_msg {
  color: green;
}

.mymodal .display_none {
  display: none;
}

.mymodal #search-wait {
  margin-top: 0.6rem;
}
.mymodal #price_usd {
  margin-top: 0.5rem;
}
.mymodal .top-margin-15 {
  margin-top: 15px;
}

.mymodal.modal-width-responsive {
  width: 90%;
  max-width: 600px;
}
.mymodal.modal-width-responsive a {
  cursor: pointer;
  font-size: 11px;
}

.mymodal.modal-width-responsive label {
  margin-bottom: 2px;
  display: block;
  text-indent: 2px;
  margin-top: 10px;
}
.mymodal.modal-width-responsive .add_modal_div,
.mymodal.modal-width-responsive li.entry_people_li {
  margin-bottom: 0px;
}

.mymodal.modal-width-responsive .txt-field-container-div-modal {
  margin-bottom: 10px;
}

.mymodal.modal-width-responsive td,
.mymodal.modal-width-responsive th,
.mymodal.modal-width-responsive label,
.mymodal.modal-width-responsive legend {
  font-size: 1.05em;
}

.mymodal.modal-width-responsive input,
.mymodal.modal-width-responsive textarea,
.mymodal.modal-width-responsive select,
.mymodal.modal-width-responsive .select-resize-ghost,
.mymodal.modal-width-responsive .select2-container .select2-choice,
.mymodal.modal-width-responsive .select2-container .select2-selection {
  font-size: 1.15em !important;
  height: 1.9em !important;
}

.mymodal .align-buttons {
  display: flex;
  justify-content: space-between;
}

.mymodal li {
  margin-bottom: 0.5rem;
}

.mymodal .checkbox-class {
  margin: 0;
  vertical-align: middle;
}
.mymodal .no-margin {
  margin: 0;
}

.mymodal input[type="text"] {
  width: 100%;
}
.mymodal h2.sgl-label-border {
  margin-bottom: 10px;
}

.mymodal .green-text {
  color: green;
}
.mymodal #subscribe_warning {
  text-align: right;
}
.mymodal .mr-btm-5 {
  margin-bottom: 5px;
}

.mymodal .sub_display_on_mobile {
  display: none;
}

@media all and (max-width: 630px) {
  .mymodal .subscription_radios {
    width: 330px !important;
  }

  .mymodal .flex-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mymodal .sub_display_on_desktop {
    display: none;
  }

  .mymodal .sub_display_on_mobile {
    display: block;
  }

  .mymodal td.sub_display_on_mobile {
    display: table-cell;
  }
  .mymodal .list-grid {
    display: none;
  }
  .mymodal .sgl-content-wrapper .tab.sub_display_on_desktop {
    display: none;
  }

  .mymodal .sgl-content-wrapper .tab.display_on_mobile {
    display: block;
  }
}

@media all and (max-width: 710px) {
  .mymodal .subscription_radios {
    width: 330px !important;
  }

  .mymodal .flex-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mymodal .sub_display_on_desktop {
    display: none;
  }

  .mymodal .sub_display_on_mobile {
    display: block;
  }

  .mymodal td.sub_display_on_mobile {
    display: table-cell;
  }

  .mymodal .list-grid > button > span {
    display: none;
  }
}

@media all and (max-width: 875px) {
  .mymodal .subscription_radios {
    width: 330px !important;
  }

  .mymodal .flex-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mymodal .sub_display_on_desktop {
    display: none;
  }

  .mymodal .sub_display_on_mobile {
    display: block;
  }

  .mymodal td.sub_display_on_mobile {
    display: table-cell;
  }
  .mymodal .set-inline {
    width: 42%;
  }
  .mymodal .set-width {
    width: 45%;
  }
  .mymodal .sw-30 {
    width: 30%;
  }
  .mymodal .details-table-head {
    width: 100px !important;
  }
  .mymodal .video-in-iframe {
    height: 350px !important;
  }
  .mymodal .subscription-modal {
    width: 70% !important;
  }
}
@media all and (max-width: 600px) {
  .mymodal .subscription_radios {
    width: 330px !important;
  }

  .mymodal .flex-video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mymodal .sub_display_on_desktop {
    display: none;
  }

  .mymodal .sub_display_on_mobile {
    display: block;
  }

  .mymodal td.sub_display_on_mobile {
    display: table-cell;
  }
  .mymodal .set-width {
    width: 77% !important;
  }
  .mymodal .list-grid {
    display: none;
  }

  .mymodal .my-row {
    flex-direction: column;
  }

  .mymodal .as-input-group {
    flex-direction: column;
    align-items: unset;
  }

  .mymodal .playlist-div {
    flex-direction: column;
    width: 129%;
  }
  .mymodal .input-group {
    width: 100%;
  }
  .mymodal .expand-style {
    margin-left: 0rem !important;
  }
  .mymodal .list .video_container {
    float: left !important;
  }
  .mymodal .list .video_details {
    width: 100%;
  }

  .mymodal .video-modal {
    height: 90%;
    width: 100%;
    background: black !important;
  }

  .mymodal .clipBoardButton {
    visibility: hidden;
  }

  .mymodal .display_it {
    display: none;
  }

  .mymodal .display_now {
    display: block !important;
  }

  .mymodal #myElement {
    height: 75% !important;
  }

  .mymodal .bottm-div {
    color: white;
    padding-top: 1rem;
  }

  .mymodal .set-inline {
    display: none;
  }

  .mymodal .top-div {
    visibility: hidden;
  }
  .mymodal .top-div_previews {
    display: none;
  }

  .mymodal .btn-class {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}

.mymodal .table > tbody > tr > td,
.mymodal .table > tbody > tr > th,
.mymodal .table > tfoot > tr > td,
.mymodal .table > tfoot > tr > th,
.mymodal .table > thead > tr > td,
.mymodal .table > thead > tr > th {
  line-height: 1.42857143;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

.mymodal table .sgl-primary-header,
.mymodal table .sgl-primary-header th,
.mymodal .sgl-primary-header,
.mymodal .sgl-primary-header * {
  color: #f7f7f7 !important;
  background-color: rgb(40, 94, 60) !important;
}

.mymodal.modal-width-responsive label,
.mymodal.modal-width-responsive legend,
.mymodal.modal-width-responsive td,
.mymodal.modal-width-responsive th {
  font-size: 1.05em;
}

.mymodal th {
  text-transform: none;
}

.mymodal table {
  width: 100%;
  margin-bottom: 1em;
  border-color: #ececec;
  border-spacing: 0;
}

.mymodal .continue-button-div button {
  padding: 0 0.6em;
}

.mymodal .button,
.mymodal button,
.mymodal input[type="button"],
.mymodal input[type="reset"],
.mymodal input[type="submit"] {
  text-rendering: optimizeLegibility;
  background-color: initial;
  border: 1px solid transparent;
  border-radius: 0;
  box-sizing: border-box;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  font-size: 0.97em;
  font-weight: bolder;
  letter-spacing: 0.03em;
  line-height: normal;
  margin-right: 1em;
  margin-top: 0;
  max-width: 100%;
  min-height: 2.5em;
  padding: 0 1.2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: uppercase;
  transition: border 0.3s, background 0.3s, box-shadow 0.3s, opacity 0.3s,
    color 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s;
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s, -webkit-transform 0.3s;
  vertical-align: middle;
}

.mymodal button {
  text-transform: none;
}

.mymodal button {
  font-family: inherit !important;
  font-size: inherit;
  line-height: inherit;
}

.mymodal ol,
.mymodal ul {
  margin: 0 0 1.5em !important;
}

.mymodal ul {
  list-style-type: none;
  padding: 0;
}

.mymodal .table-scroll {
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
  display: block;
  overflow-x: hidden;
  max-width: 100% !important;
}

.mymodal .table-striped-3 > tbody > tr:nth-of-type(4n + 2),
.mymodal .table-striped-3 > tbody > tr:nth-of-type(4n + 3),
.mymodal .table-striped > tbody > tr:nth-of-type(odd),
.sgl-plugin-page .sgl-plugin .table-striped-3 > tbody > tr:nth-of-type(4n + 2),
.sgl-plugin-page .sgl-plugin .table-striped-3 > tbody > tr:nth-of-type(4n + 3),
.sgl-plugin-page .sgl-plugin .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f1f1f1 !important;
}

.mymodal .scroll_table tbody > tr,
.mymodal .scroll_table thead > tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
