.sgl-grid .sgl-title-section h1{
	font-size: 28px;
	font-weight: bold;
	padding: 0px 15px;
	margin-bottom: 0px;
}

.sgl-grid .card-deck {
	overflow: hidden;
	display: grid;
	grid-auto-rows: 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 15px;
	padding: 15px 15px;
	-moz-osx-font-smoothing: auto;
	font-size: 100%;
	line-height: 100%;
}

.sgl-viewAll .card-deck {
	overflow: hidden;
	display: grid;
	grid-auto-rows: 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 15px;
	padding: 15px 15px;
	-moz-osx-font-smoothing: auto;
	font-size: 100%;
	line-height: 100%;
}

.sgl-grid .card-deck .sgl-item{
	position:relative;
	padding: 0 15px;
}

.sgl-grid .card-deck .sgl-item:last-child {
	padding-right: 0;
}

.sgl-grid .card-deck .sgl-post {
	margin-bottom: 15px;
}


.sgl-grid .card-deck .sgl-standard-post {
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.sgl-grid .card-deck .sgl-standard-post .sgl-post-content {
    padding-top: 10px;
}

.sgl-grid .card-deck .sgl-standard-post .sgl-post-content h2 {
    color: #333333;
    font-size: 13px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 10px;
}

.sgl-grid .card-deck .sgl-standard-post .sgl-post-content h2 a {
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #333333;
}

.sgl-grid .card-deck  .sgl-standard-post .sgl-post-content h2 a:hover {
    color: #f44336;
}

.sgl-grid .sgl-post .sgl-post-gallery {
	position: relative;
	cursor: pointer;
}

.sgl-grid .sgl-post-gallery .sgl-play{
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
}

.sgl-grid .sgl-post-gallery .sgl-play .sgl-play-icon{
	height: 40px;
	width: 40px;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	background: rgba(220, 220, 220, 0.6);
	position: absolute;
	align-items: center;
	font-size: 18px;
	display: flex;
	color: #fff;
	margin-top: -20px;
	margin-left: -20px;
	text-indent: 4px;
}

@media (max-width: 767px) {
	.sgl-grid .card-deck {
		grid-template-columns: repeat(2, 1fr) !important;
	}
}

@media (max-width: 520px) {
	.sgl-grid .card-deck {
		grid-template-columns: repeat(1, 1fr) !important;
		grid-auto-rows: minmax(min-content, max-content);
	}
}