.sgl-single-video .sgl-single-video-wrapper {
    padding: 20px 0px;
    line-height: 100%;
    font-size: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    margin:0px;
}

.sgl-single-video.coth .sgl-single-video-wrapper{
    background-color: #F7E5E2;
}

.sgl-related-content .card-deck, .sgl-related-content .sgl-title-section {
    padding:2px 0px;
}

.sgl-single-video:after {
    content: "";
    display: table;
    clear: both;
}

.sgl-related-content .sgl-title-section {
	padding:0px;
}

.sgl-related-content .sgl-title-section h1{
	margin-bottom:20px;
    margin-top:0px;
    font-size:24px;
    padding:0px;
}

.sgl-related-content section.sgl-videos-scroller.geg .sgl-videos-scroller-box::after,
.sgl-related-content section.sgl-videos-scroller.geg .sgl-videos-scroller-box::before {
    background: #e0d8c7;
}

.sgl-related-content section.sgl-videos-scroller.coth .sgl-videos-scroller-box::after,
.sgl-related-content section.sgl-videos-scroller.coth .sgl-videos-scroller-box::before {
    background: #F7E5E2;
}

.sgl-single-video .sgl-video-details{
    padding-top:0px;
    overflow:hidden;
}

.sgl-single-video .sgl-video-details .sgl-details{
    display:flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.sgl-single-video .sgl-video-details .sgl-details .left{
    width:50%;
}

.sgl-single-video .sgl-video-details .sgl-details .right{
    padding: 0% 0% 0% 7%;
    width:50%;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-video-title{
    font-size: 30px;
    font-weight:bold;
    line-height: 36px;
    word-break: break-word;
    margin-bottom:10px;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-video-description{
    box-sizing: border-box;
    color:rgb(95, 94, 94);
    font-size:16px;
    line-height:20px;
    text-align: right;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-video-status{
    font-weight: bold;
    font-size:13px;
    overflow:hidden;
    text-transform: uppercase;
    margin-bottom: 15px;
    line-height:16px;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-video-status .sgl-indicator{
    height:17px;
    width:17px;
    display:block;
    float:left;
    border-radius:50%;
    background-color:#aa0000;
    margin-right:10px;
    animation-duration: 2s;
    animation-name: indicator;
    animation-iteration-count: infinite;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-date{
    font-weight: bold;
    color:rgb(95, 94, 94);
    font-size:15px;
}

.sgl-single-video.coth .sgl-video-details .sgl-details .sgl-date span{
    font-weight: bold;
    color: #000000;
    font-family: "Work Sans", sans-serif;
    font-size:17px;
}

.sgl-single-video .sgl-video-details .sgl-details .sgl-back-livestream {
    font-size: 15px;
    font-weight: 700;
    background-color: #285E3C;
    border-radius: 14px 14px 14px 14px;
    padding: 12px 24px;
    color:#fff;
    margin-top:25px;
    display: inline-block;
    text-decoration: none;
}


@keyframes indicator {
    from {background-color: #aa0000;}
    to {background-color: transparent;}
}

.sgl-related-content{
    padding: 0px;
}

.sgl-related-content section.sgl-videos-scroller.geg .sgl-title-section h1 span{
    font-size:30px;
    line-height:36px
}

.sgl-related-content section.sgl-videos-scroller.coth .sgl-title-section h1 span{
    font-size:33px;
    line-height:36px
}

.sgl-related-content section.sgl-videos-scroller.geg .sgl-title-section h1 span:after,
.sgl-related-content section.sgl-videos-scroller.coth .sgl-title-section h1 span:after{
    display: none;
}


.sgl-single-video .sgl-column,
.sgl-single-video .sgl-video-details {
    margin:0px auto;
    display:block;
    width:82%;
    max-width: 1183px;
}

.sgl-related-content,
.sgl-single-video .sgl-related-sponsors{
    margin:0px auto;
    display:block;
}

.sgl-single-video .sgl-related-sponsors{
    border-top:1px solid #eaeaea;
    border-bottom:1px solid #eaeaea;
    margin-top: 20px;
    margin-bottom: 40px;
    overflow: hidden;
	display: grid;
	grid-auto-rows: 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding:20px 5px;
}

.sgl-single-video .sgl-related-sponsors .sgl-sponsor{
    text-align:center;
    display: grid;
    align-items: center;
    justify-items: center;
}

.sgl-single-video .sgl-related-sponsors .sgl-sponsor img{
    width:100%;
    max-width:290px;
}

.sgl-single-video .sgl-column .sgl-row{
    margin-bottom:35px;
}

.sgl-single-video .sgl-column img {
    max-width: 100%;
    height: auto;
    width: 100%;
    border: 1px solid #aaa;
    box-sizing: border-box;
}

.sgl-single-video .sgl-row {
    display:block !important;
    margin-bottom:10px;
}

.sgl-single-video .sgl-row p{
    margin-top:0px;
    margin-bottom:10px;
    color: rgb(251, 183, 0);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
}

.sgl-single-video .sgl-row p span{
    background-color: #000;
    color:#fff;
    padding:2px 5px;
    border-radius: 3px;
}

.sgl-single-video .sgl-row .sgl-img {
    position: relative;
}

.sgl-single-video .sgl-row .sgl-img .sgl-play {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.sgl-single-video .sgl-row .sgl-img .sgl-play .sgl-play-icon {
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.6);
    position: absolute;
    align-items: center;
    font-size: 18px;
    display: flex;
    color: #fff;
    margin-top: -20px;
    margin-left: -20px;
    text-indent: 4px;
}

.sgl-single-video .sgl-row .sgl-img .sgl-play .sgl-play-icon-large {
    height: 80px;
    width: 80px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: rgba(220, 220, 220, 0.6);
    position: absolute;
    align-items: center;
    font-size: 32px;
    display: flex;
    color: #fff;
    margin-top: -40px;
    margin-left: -40px;
    text-indent: 8px;
}


.sgl-single-video .sgl-row .sgl-img .sgl-play span {
    display: inline-block;
    margin: 0px auto;
}

.sgl-single-video .sgl-video-details .sgl-video-description{
    font-size: 16px;
    line-height: 24px;
}

.sgl-single-video .sgl-login-bar{
    background: #333;
    width: 350px;
    margin: 0px auto;
    border-radius: 3px;
    text-align: center;
    margin-bottom: 20px;
    border: 1px solid #000;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner{
    margin:0px auto;
    padding:0px;
    list-style-type: none;
    overflow:hidden;
    height: 30px;
    line-height: 30px;
    display:flex;
    flex-direction: row;
    align-items: center;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li{
    color:#e0d8c7;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom:0px;
    width: 50%;
    line-height:14px;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li:last-child{
    border-left: 1px solid #000;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li a{
    color:#fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    display:block;
    height: 30px;
    line-height: 30px;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li a:hover{
    background-color:#000;
}

.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li a.alt{
    color:#fff;
}

.sgl-single-video.coth .sgl-login-bar .sgl-login-bar-inner li a.alt,
.sgl-single-video .sgl-login-bar .sgl-login-bar-inner li a{
    font-family: "Work Sans", sans-serif;
}

.sgl-related-content .sgl-see-all{
    background: #000 !important;
    color:#fff !important;
}

.sgl-single-video * {
    font-family: Freigeist,sans-serif;
}

.sgl-single-video.coth * {
    font-family: Playfair Display;
}

@media (max-width: 767px) {

    .sgl-single-video .sgl-video-details .sgl-details{
        flex-direction: column;
    }

    .sgl-single-video .sgl-video-details .sgl-details .left,
    .sgl-single-video .sgl-video-details .sgl-details .right{
        width:100%;
        padding:0px;
    }

    .sgl-single-video .sgl-video-details .sgl-details .right .sgl-video-description{
        margin-top:15px;
        text-align: left;    
    }
    
    .sgl-single-video .sgl-video-details .sgl-details .sgl-date{
        line-height:19px;
    }

    .sgl-single-video .sgl-row .sgl-img .sgl-play .sgl-play-icon-large {
        height: 50px;
        width: 50px;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background: rgba(220, 220, 220, 0.6);
        position: absolute;
        align-items: center;
        font-size: 24px;
        display: flex;
        color: #fff;
        margin-top: -25px;
        margin-left: -25px;
        text-indent: 3px;
    }


    .sgl-single-video .sgl-column,
    .sgl-single-video .sgl-video-details,
    .sgl-related-content,
    .sgl-single-video .sgl-related-sponsors {
        width: 100% !important;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        max-width: 100% !important;
    }

    .sgl-single-video .sgl-login-bar{
        width: 100%;
        max-width:340px;
    }

    .sgl-single-video .sgl-column{
        max-width:100% !important;
    }

    .sgl-single-video .sgl-related-sponsors{
        grid-template-columns: repeat(2, 1fr);
    }

    .sgl-related-content section.sgl-videos-scroller.geg .sgl-container,
    .sgl-related-content section.sgl-videos-scroller.coth .sgl-container{
        padding:0px 15px;
    }
    
    .sgl-single-video .sgl-video-details .sgl-details .sgl-video-title{
        font-size: 20px;
        line-height:24px;
    }

    .sgl-related-content section.sgl-videos-scroller.geg .sgl-title-section h1 span{
        font-size: 20px;
        line-height:26px;
    }

    .sgl-related-content section.sgl-videos-scroller.coth .sgl-title-section h1 span{
        font-size: 23px;
        line-height:26px;
    }
}